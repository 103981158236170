<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div
        v-for="(page, index) in pages"
        :key="index"
        class="preview-inner">
        <v-row dense class="mt-0">
          <v-col class="col-8 pt-0">
            <h2 class="text-center pt-5">PROFORMA INVOICE</h2>
            <p class="text-center" v-if="pageCount > 1">Page {{ (index + 1) }} of {{ pageCount }}</p>
            <p
              v-if="page.invoice.vatStatus === 1 && page.invoice.status !== 1"
              class="text-small text-center">
              This invoice is issued in compliance with HMRC Domestic<br />
              Reverse Charging Scheme for the Construction Industry
            </p>
          </v-col>
          <v-col class="col-4 pt-0">
            <img width="180px" src="../../assets/logo.png" alt="" />
            <p class="text-small mb-0">
              Riggott &amp; Co Limited<br />
              Unit X, Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Nottinghamshire, NG22 0NL<br />
              Tel: (01777) 872525 Fax: (01777) 872626<br />
              <span class="text-tiny">
                Email: info@riggott.co.uk Web: www.riggott.co.uk
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="col-8">
            <p style="min-height:100px" class="text-mid text-heavy bordered pa-1">
              {{ page.invoice.customer }}<br />
              <span v-if="page.invoice.addressOne">
                <span v-if="page.invoice.addressOne">{{ page.invoice.addressOne }}, <br /></span>
                <span v-if="page.invoice.addressTwo">{{ page.invoice.addressTwo }}, </span>
                <span v-if="page.invoice.addressThree">{{ page.invoice.addressThree }}, </span>
                <span v-if="page.invoice.addressFour">{{ page.invoice.addressFour }}, </span>
                <span v-if="page.invoice.addressFive">{{ page.invoice.addressFive }}, </span>
                <span v-if="page.invoice.postcode"><br />{{ page.invoice.postcode }}</span>
              </span>
            </p>
          </v-col>
          <v-col class="col-4">
            <div style="min-height:100px" class="bordered pa-1">
              <v-row class="pa-0 ma-0">
                <v-col class="col-5 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0">
                    Document no:
                  </p>
                </v-col>
                <v-col class="col-7 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0 text-right">
                    {{ page.invoice.jobNumber }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="col-5 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0">
                    Date/Tax Point:
                  </p>
                </v-col>
                <v-col class="col-7 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0 text-right">
                    {{ page.invoice.date | tinyDate }}<br />
                  </p>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="col-12 pa-0 ma-0">
                  <p class="text-small pa-0 ma-0">
                    PO: <span class="float-right">{{ page.invoice.poNumber }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="col-5 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0">
                    <strong>Account ref:</strong><br />
                  </p>
                </v-col>
                <v-col class="col-7 pa-0 ma-0">
                  <p class="text-mid pa-0 ma-0 text-right">
                    <strong>{{ page.invoice.accountReference }}</strong>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="page.invoice.vatStatus === 1 && page.invoice.status !== 1"
          dense>
          <v-col class="col-8">
            <p class="text-mid mb-0">
              Customer to pay VAT of
              {{ ((page.invoice.value * 1.2) - page.invoice.value) | priceInPounds | currency }}
              to HMRC
            </p>
          </v-col>
          <v-col class="col-4">
            <p class="text-mid mb-0">
              Applicable VAT rate is 20%
            </p>
          </v-col>
        </v-row>
        <div style="min-height: 133mm">
          <v-row dense>
            <v-col class="col-6 bordered no-right">
              <span class="text-mid text-heavy">
                Description
              </span>
            </v-col>
            <v-col class="col-1 bordered no-right">
              <span class="text-mid text-heavy">
                Units
              </span>
            </v-col>
            <v-col class="col-1 bordered no-right">
              <span class="text-mid text-heavy">
                Qty
              </span>
            </v-col>
            <v-col class="col-2 bordered no-right">
              <span class="text-mid text-heavy">
                Rate
              </span>
            </v-col>
            <v-col class="col-2 text-right bordered">
              <span class="text-mid text-heavy">
                Value
              </span>
            </v-col>
          </v-row>
          <div v-if="index === 0">
            <v-row v-if="page.invoice.additional_codes.length > 0" dense>
              <v-col class="col-6 pt-2 pb-4 border-left text-mid">
                <div class="unspaced-p" v-html="page.invoice.additional_codes"></div>
              </v-col>
              <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 text-right border-left border-right">&nbsp;</v-col>
            </v-row>
          </div>
          <v-row dense v-for="(line, index) in page.lines" :key="`l_${index}`">
            <v-col class="col-6 pt-0 pb-0 lh-1-3 border-left">
              <span class="text-mid">
                {{ line.description }},
                {{ line.material }},
                {{ line.colour }}
              </span>
            </v-col>
            <v-col class="col-1 pt-0 pb-0 lh-1-3 border-left">
              <span class="text-mid">{{ line.units }}</span>
            </v-col>
            <v-col class="col-1 pt-0 pb-0 lh-1-3 border-left">
              <span class="text-mid">{{ line.quantity }}</span>
            </v-col>
            <v-col class="col-2 pt-0 pb-0 lh-1-3 border-left">
              <span class="text-mid">{{ line.rate | priceInPounds | currency }}</span>
            </v-col>
            <v-col class="col-2 pt-0 pb-0 lh-1-3 text-right border-left border-right">
              <span class="text-mid">{{ (line.quantity * line.rate) | priceInPounds | currency }}</span>
            </v-col>
          </v-row>
          <div v-if="pageCount === (index + 1)">
            <v-row dense>
              <v-col class="col-6 pt-5 border-left text-mid">
                <div class="unspaced-p" v-html="page.invoice.description_notes"></div>
              </v-col>
              <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-1 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 text-right border-left border-right">&nbsp;</v-col>
            </v-row>
            <v-row v-for="n in blankLines" :key="n" dense>
              <v-col class="col-6 pt-0 pb-0 lh-1-3 border-left">&nbsp;</v-col>
              <v-col class="col-1 pt-0 pb-0 lh-1-3 border-left">&nbsp;</v-col>
              <v-col class="col-1 pt-0 pb-0 lh-1-3 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 pb-0 lh-1-3 border-left">&nbsp;</v-col>
              <v-col class="col-2 pt-0 pb-0 lh-1-3 text-right border-left border-right">&nbsp;</v-col>
            </v-row>
          </div>
          <v-row dense>
            <v-col class="border-top"></v-col>
          </v-row>
        </div>
        <v-row dense class="pt-2">
          <v-col class="col-7">
            <div style="min-height:110px" class="text-small text-heavy bordered pa-1 mb-0">
              Delivery location:<br />
              {{ page.invoice.siteLocation }}<br />
              <span>Description:&nbsp;</span>
              <div class="inline unspaced-p" v-html="page.invoice.workDescription"></div>
              <div class="clear-both">Contact: {{ page.invoice.contact  }}</div>
            </div>
          </v-col>
          <v-col class="col-5" v-if="pageCount === 1">
            <p style="min-height:110px" class="text-mid bordered pa-1 mb-0">
              <span class="text-heavy">Sub-total:</span>
              <span class="float-right text-heavy">
                {{ page.invoice.value | priceInPounds | currency }}
              </span><br />
              <span v-if="page.invoice.status !== 1" class="text-heavy">
                <span v-if="page.invoice.vatStatus === 2">
                  VAT @20%:
                </span>
              </span>
              <span v-if="page.invoice.status !== 1" class="float-right text-heavy">
                <span v-if="page.invoice.vatStatus === 2">
                  {{ page.invoice.vat | priceInPounds | currency }}
                </span>
              </span><br /><br />
              <span class="text-heavy">Total:</span>
              <span class="text-heavy float-right">
                <span v-if="page.invoice.status !== 1">
                  <span v-if="page.invoice.vatStatus === 2">
                    {{ (page.invoice.value + page.invoice.vat) | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ page.invoice.value | priceInPounds | currency }}
                  </span>
                </span>
                <span v-else>
                  {{ page.invoice.value | priceInPounds | currency }}
                </span>
              </span>
            </p>
          </v-col>
          <v-col class="col-5" v-if="pageCount === 2 && index === 0">
            <p style="min-height:110px" class="text-mid bordered pa-1">
              <span class="text-heavy">Sub-total:</span>
              <span class="float-right text-heavy">
                {{ page.subTotal | priceInPounds | currency }}
              </span>
              <br />
              <br />
              <br />
              <br />
            </p>
          </v-col>
          <v-col class="col-5" v-if="pageCount === 2 && index === 1">
            <p style="min-height:110px" class="text-mid bordered pa-1">
              <span class="text-heavy">Sub-total:</span>
              <span class="float-right text-heavy">
                {{ page.invoice.value | priceInPounds | currency }}
              </span><br />
              <span v-if="page.invoice.status !== 1" class="text-heavy">
                <span v-if="page.invoice.vatStatus === 2">
                  VAT @20%:
                </span>
              </span>
              <span v-if="page.invoice.status !== 1" class="float-right text-heavy">
                <span v-if="page.invoice.vatStatus === 2">
                  {{ page.invoice.vat | priceInPounds | currency }}
                </span>
              </span><br /><br />
              <span class="text-heavy">Total:</span>
              <span class="text-heavy float-right">
                <span v-if="page.invoice.status !== 1">
                  <span v-if="page.invoice.vatStatus === 2">
                    {{ (page.invoice.value + page.invoice.vat) | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ page.invoice.value | priceInPounds | currency }}
                  </span>
                </span>
                <span v-else>
                  {{ page.invoice.value | priceInPounds | currency }}
                </span>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row dense class="pt-2">
          <v-col class="col-10 border-top pb-0">
            <p class="text-center text-mid mb-0">
              <span
                v-if="page.invoice.status === 1"
                class="text-orange text-heavy">
                This is not a VAT invoice
              </span>
              <span class="text-heavy">Payment terms:</span>
              Payment is due 30 days from date of invoice
              <span class="text-heavy">Bank:</span> NatWest<br />
              <span class="text-heavy">Sort Code:</span> 60-17-28
              <span class="text-heavy">a/c no:</span> 90117395
              &nbsp;&nbsp;&nbsp;We also accept payment by credit/debit card
            </p>
          </v-col>            
          <v-col class="col-2 border-top pb-0">
            <p class="text-center">
              <img width="200px" src="../../assets/payment_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0" dense>
          <v-col class="col-12 pt-0">
            <p class="text-center text-mid">
              Company registered in England Number 2985947
              VAT Registration Number GB 598 6171 83    UTR 74932 96911<br />
              <img width="700px" src="../../assets/riggott_accreditation_logos.png" alt="" />
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'InvoicesPrintView',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      pages: [],
      blankLines: 0,
      pageCount: 0, 
    };
  },
  methods: {
    goBack() {
      this.$router.go('-1');
    },
    print() {
      window.print();
    },
    getInvoice() {
      axios.get(`/invoices/getSinglePrint/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.pages = response.data.pages;
        this.blankLines = response.data.blankLines;
        this.pageCount = response.data.pageCount;
      });
    },
  },
  mounted() {
    this.getInvoice();
  },
}
</script>

<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
